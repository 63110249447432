import { createContext, useContext } from "react";
import { IOrder } from "shared/lib/models/order";

interface IAdminContext {
  readonly actions: {
    readonly countOrders: () => Promise<number>;
    readonly getOrders: (
      start?: number,
      limit?: number,
      search?: string,
    ) => Promise<IOrder[]>;
  };
}

export const AdminContext = createContext<IAdminContext | undefined>(undefined);

export function useAdminStore() {
  return useContext(AdminContext) as IAdminContext;
}
