import { PersistenceKeys } from "../base";
import jwtDecode from "jwt-decode";

function decodeToken(token: string) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return undefined;
  }
}

export function setToken(token: string) {
  const payload = decodeToken(token);
  if (payload) {
    const now = new Date().getTime() / 1000;
    const expUnix = (payload.exp - now) / 2 + now;
    sessionStorage.setItem(
      PersistenceKeys.AuthToken,
      JSON.stringify({ token, exp: expUnix }),
    );
    return true;
  }
  return false;
}

export function getToken() {
  const data = sessionStorage.getItem(PersistenceKeys.AuthToken);

  if (data) {
    return JSON.parse(data).token;
  }
  return undefined;
}

export function expiryDate() {
  const data = sessionStorage.getItem(PersistenceKeys.AuthToken);

  if (data) {
    return new Date(JSON.parse(data).exp * 1000);
  }
  return undefined;
}

export function isExpired() {
  const expiry = expiryDate();

  if (!expiry) {
    throw new Error("Expiry date must be defined");
  }

  return new Date().getTime() > expiry.getTime();
}
