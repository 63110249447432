import React from "react";
import { IOrder } from "shared/lib/models/order";
import Confirmation from "../../../order/views/confirmation";
import OrderRequest from "../../../../../models/orderRequest";
import { useGlobalStore } from "../../../../../store";

interface IShowViewProps {
  readonly order: IOrder;
  readonly onNavigateBack: () => void;
}

export default function ShowView({ order, onNavigateBack }: IShowViewProps) {
  const { i18n } = useGlobalStore();
  return (
    <div>
      <button className="tiny-btn tiny-btn--blush" onClick={onNavigateBack}>
        {i18n.t("admin_list_btn_back")}
      </button>
      {!!order && !!order.createdAt && !!order.request && (
        <Confirmation
          adminMode
          order={order}
          orderCreatedAt={order.createdAt}
          orderRequest={order.request}
        />
      )}
    </div>
  );
}
