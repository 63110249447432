import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  FormEvent,
  useMemo,
} from "react";

import style from "./index.module.styl";
import FormInput from "../../../../forms/input";
import { useGlobalStore } from "../../../../../store";
import { login } from "../../../../../endpoints/auth";
import { ILocale } from "shared/lib/locales/base";
import C from "classnames";
import Spinner from "../../../../spinner";
import { rem, toRGB, COD_GREY } from "../../../../../utils/style";

interface IAuthViewProps {
  readonly onLogin: (token: string) => void;
}

export default function AuthView({ onLogin }: IAuthViewProps) {
  const { i18n } = useGlobalStore();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const isDisabled = useMemo(() => {
    return !identifier || !password;
  }, [identifier, password]);

  const [errorKey, setErrorKey] = useState<keyof ILocale | undefined>(
    undefined,
  );

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const token = await login({
        identifier,
        password,
      });

      if (!isMounted.current) {
        return;
      }

      onLogin(token);
    } catch (e) {
      if (!isMounted.current) {
        return;
      }

      setErrorKey("admin_auth_error");
    }

    setIsLoading(false);
  };
  return (
    <form className={style.adminForm} onSubmit={handleLogin}>
      {!!errorKey && (
        <div className={style.adminFormError}>{i18n.t(errorKey)}</div>
      )}
      <div className={style.adminFormField}>
        <FormInput
          inputProps={{
            placeholder: i18n.t("admin_auth_identifier"),
            onChange: e => {
              setIdentifier(e.target.value);
            },
          }}
        />
      </div>
      <div className={style.adminFormField}>
        <FormInput
          inputProps={{
            placeholder: i18n.t("admin_auth_password"),
            type: "password",
            onChange: e => {
              setPassword(e.target.value);
            },
          }}
        />
      </div>
      <div className={style.adminFormField}>
        <button
          disabled={isDisabled}
          type="submit"
          className={C("btn btn--animate-scale btn--blush", {
            disabled: isDisabled,
          })}
        >
          {i18n.t("admin_auth_login")}
          {isLoading && (
            <div className={style.adminFormSpinnerWrapper}>
              <div className={style.adminFormSpinner}>
                <Spinner borderWidth={rem(3)} color={toRGB(COD_GREY)} />
              </div>
            </div>
          )}
        </button>
      </div>
    </form>
  );
}
