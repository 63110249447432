import React, { useState, useCallback, useRef, useEffect } from "react";

import style from "./index.module.styl";
import { IOrder } from "shared/lib/models/order";
import { useAdminStore } from "../../store";
import { formatIntCurrency } from "shared/lib/utils/currency";
import C from "classnames";
import FormInput from "../../../../forms/input";
import { useGlobalStore } from "../../../../../store";
import Spinner from "../../../../spinner";
import { rem, toRGB } from "../../../../../utils/style";

const PAGE_SIZE = 10;

interface IListViewProps {
  readonly onOrderView: (order: IOrder) => void;
}

export default function ListView({ onOrderView }: IListViewProps) {
  const { i18n } = useGlobalStore();
  const [page, setPage] = useState<IOrder[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const {
    actions: { getOrders },
  } = useAdminStore();

  const isFetching = useRef(false);
  const [fetchingState, setFetchingState] = useState(false);
  const isMounted = useRef(true);
  const [orderIdSearch, setOrderIdSearch] = useState("");
  const triggerReload = useRef(false);

  async function initialLoad() {
    fetchPage(0);
  }

  useEffect(() => {
    initialLoad();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchPage = async (_pageNumber: number) => {
    isFetching.current = true;
    setFetchingState(true);

    let nextPage = _pageNumber;
    let _page = await getOrders(
      _pageNumber * PAGE_SIZE,
      PAGE_SIZE,
      orderIdSearch,
    );

    while (triggerReload.current) {
      nextPage = 0;
      triggerReload.current = false;
      _page = await getOrders(0, PAGE_SIZE, orderIdSearch);
    }

    isFetching.current = false;
    setFetchingState(false);

    if (!isMounted.current) {
      return;
    }

    if (_page.length > 0 || nextPage === 0) {
      setPage(_page);
      setPageNumber(nextPage);
    }
  };

  const handleNextClick = async () => {
    if (isFetching.current) {
      return;
    }

    fetchPage(pageNumber + 1);
  };

  const handleBackClick = async () => {
    if (pageNumber === 0) {
      return;
    }

    if (isFetching.current) {
      return;
    }

    fetchPage(pageNumber - 1);
  };

  const handleViewClick = useCallback((order: IOrder) => {
    onOrderView(order);
  }, []);

  useEffect(() => {
    if (isFetching.current) {
      triggerReload.current = true;
      return;
    }

    fetchPage(0);
  }, [orderIdSearch]);

  return (
    <div>
      <div className={style.adminListTop}>
        <div className={style.adminListSearch}>
          <FormInput
            inputProps={{
              placeholder: i18n.t("admin_list_order_id"),
              onChange: e => setOrderIdSearch(e.target.value),
            }}
          />
        </div>
        {fetchingState && (
          <div className={style.adminListSpinnerWrapper}>
            <div className={style.adminListSpinner}>
              <Spinner borderWidth={rem(4)} color={toRGB([255, 255, 255])} />
            </div>
          </div>
        )}
      </div>

      <table className={style.adminListTable}>
        <tr className={C(style.adminListRow, style.adminListHeaderRow)}>
          <th className={style.adminListCell}>
            {i18n.t("admin_list_order_id")}
          </th>
          <th className={style.adminListCell}>
            {i18n.t("admin_list_model_name")}
          </th>
          <th className={style.adminListCell}>{i18n.t("admin_list_price")}</th>
          <th className={style.adminListCell}>
            {i18n.t("admin_list_created_at")}
          </th>
          <th className={style.adminListCell}>
            {i18n.t("admin_list_actions")}
          </th>
        </tr>
        {page.map(order => {
          const { obfuscatedID, model_name, price, createdAt } = order;
          return (
            <tr className={style.adminListRow}>
              <td className={style.adminListCell}>{obfuscatedID}</td>
              <td className={style.adminListCell}>{model_name}</td>
              <td className={style.adminListCell}>
                {formatIntCurrency(price)}
              </td>
              <td className={style.adminListCell}>
                {createdAt.toLocaleDateString()}
              </td>
              <td className={style.adminListCell}>
                <button
                  className="tiny-btn tiny-btn--blush"
                  onClick={() => handleViewClick(order)}
                >
                  {i18n.t("admin_list_btn_view")}
                </button>
              </td>
            </tr>
          );
        })}
      </table>
      <button
        className={C("tiny-btn tiny-btn--blush", style.adminListNavigationBtn)}
        onClick={handleBackClick}
      >
        {i18n.t("admin_list_btn_back")}
      </button>
      <button
        className={C("tiny-btn tiny-btn--blush", style.adminListNavigationBtn)}
        onClick={handleNextClick}
      >
        {i18n.t("admin_list_btn_next")}
      </button>
    </div>
  );
}
