import { postJson } from "../utils/requests";
import { endpoints } from ".";

interface ILoginRequest {
  readonly identifier: string;
  readonly password: string;
}

export async function login(request: ILoginRequest): Promise<string> {
  const response = await postJson<any>(endpoints.login, request);

  if (!response.jwt) {
    throw new Error("Response did not contain JWT token");
  }

  return response.jwt;
}
