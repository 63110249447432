import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import page from "..";
import { useGlobalStore } from "../../../store";
import GeneralLayout from "../../layouts/general";
import Header from "../../header";
import CenterColumn from "../../centerColumn";
import { getToken, expiryDate, setToken } from "../../../persistance/auth";
import AuthView from "./views/auth";
import { AdminContext } from "./store";
import { countOrders, getOrders } from "../../../endpoints/orders";
import ListView from "./views/list";
import { IOrder } from "shared/lib/models/order";
import ShowView from "./views/show";
import style from "./index.module.styl";

function Admin() {
  const { i18n, breadcrumbs } = useGlobalStore();
  const isMounted = useRef(true);
  const [orderInView, setOrderInView] = useState<IOrder | undefined>(undefined);
  const [didFetchToken, setDidFetchToken] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [token, _setToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const _token = getToken();

    if (_token) {
      const expiry = expiryDate();
      const now = new Date();
      if (expiry && now < expiry) {
        setTimeout(() => {
          if (!isMounted.current) {
            return;
          }

          _setToken(undefined);
        }, expiry.getTime() - now.getTime());
        _setToken(_token);
      }
    }
    setDidFetchToken(true);
  }, []);

  const handleLogin = useCallback((token: string) => {
    setToken(token);
    _setToken(token);
  }, []);

  const adminActions = useMemo(() => {
    if (!token) {
      return undefined;
    }

    return {
      countOrders: () => countOrders(token),
      getOrders: (start?: number, limit?: number, search?: string) =>
        getOrders(token, start, limit, search),
    };
  }, [token]);

  if (!didFetchToken) {
    return null;
  }

  return (
    <GeneralLayout breadcrumbs={breadcrumbs}>
      <CenterColumn className={style.admin}>
        <Header
          titleDesktop={i18n.t("admin_title")}
          titleMobile={i18n.t("admin_title")}
        />
        {!token && <AuthView onLogin={handleLogin} />}
        {!!token && (
          <AdminContext.Provider
            value={{
              actions: adminActions as any,
            }}
          >
            <div className={style.adminTopButtons}>
              <button
                className="tiny-btn tiny-btn--blush"
                onClick={() => _setToken(undefined)}
              >
                {i18n.t("admin_logout")}
              </button>
            </div>

            {!orderInView && (
              <ListView onOrderView={order => setOrderInView(order)} />
            )}
            {!!orderInView && (
              <ShowView
                order={orderInView}
                onNavigateBack={() => setOrderInView(undefined)}
              />
            )}
          </AdminContext.Provider>
        )}
      </CenterColumn>
    </GeneralLayout>
  );
}

export default page(Admin);
